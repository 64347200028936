//React imports
import React, { useEffect, useState } from "react";
import useContent from "../hooks/useContent";
import useImagePreloader from "../hooks/useImagePreloader";
import { Author } from "../interfaces/author";
import { ContactUsDoc } from "../interfaces/contact-us";
import { AnyDoc } from "../interfaces/general";
import { HomeDoc } from "../interfaces/home";
import { NavigationDoc } from "../interfaces/navigation";
import { Project, ProjectsDoc } from "../interfaces/projects";
import { StudioDoc } from "../interfaces/studio";
import { WorkWithUsDoc } from "../interfaces/work-with-us";

//Definitions
/**Context functions */
interface PreloadContextProviderValues {
  /**Get a specific view data */
  getViewData: (
    id:
      | "Navigation"
      | "Footer"
      | "Home"
      | "Studio"
      | "Contact"
      | "SingleCase"
      | "Work"
      | "Projects"
  ) => AnyDoc | undefined;
  /**Get a all the projects */
  getProjectsData: () => Project[] | undefined,

  /**Get all the authors */
  getAuthorsData: () => Author[] | undefined,
  /**Is ready to load flag */
  isReadyToLoad: boolean;
}

/**Preload context */
export const PreloadContext = React.createContext<PreloadContextProviderValues>(
  {
    getViewData: () => undefined,
    getProjectsData: () => undefined,
    getAuthorsData: () => undefined,
    isReadyToLoad: true,
  }
);

const PreloadProvider = ({ children }: { children: any }) => {
  //Hooks
  /**All documents snap shot */
  const allDocsSnap: AnyDoc[] = useContent().getAll();

  /**All Projects snap shot */
  const allProjectsSnap: Project[] = useContent().getProjects();

  /**All Authors snap shot */
  const allAuthorsSnap: Author[] = useContent().getAuthors();

  //Preload hook
  const { bulkPreload } = useImagePreloader();

  //Data
  //All images loaded flag
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  //Methods
  /**Get view data */
  const getViewData = (
    id:
      | "Home"
      | "Navigation"
      | "Footer"
      | "Studio"
      | "Contact"
      | "SingleCase"
      | "Work"
      | "Projects"
  ): AnyDoc | undefined =>
    allDocsSnap.find((doc) => (doc as any).contentName === id);
  /**Get Projects data */
    const getProjectsData = ():Project[] =>
    {
        let projectArray:Project[] = []

        allProjectsSnap.forEach(((doc:any) =>{
        let project: Project = doc
        project.id = doc.NO_ID_FIELD
        projectArray.push(project)
      }))
      return projectArray
    }
  /**Get Authors data */
    const getAuthorsData = ():Author[] =>  
    {
      
      let authorArray: Author[] = []
      allAuthorsSnap.forEach((doc:any)=> {
        console.log("AUTHORS DOC",doc)
        let author:Author = doc
        author.id = doc.NO_ID_FIELD
        authorArray.push(author)
      })
      return authorArray
    }
    
  /**Preload images */
  const preloadImages = async () => {
    if (allDocsSnap && allAuthorsSnap && allProjectsSnap) {
      /**Home data */
      const homeData = getViewData("Home")! as HomeDoc;

      /**Studio data */
      const studioData = getViewData("Studio")! as StudioDoc;

      /**Navigation data */
      const navigationData = getViewData("Navigation")! as NavigationDoc;

      /**Contact data */
      const contactData = getViewData("Contact")! as ContactUsDoc;

      /**Case data */
      const singleCaseData = getViewData("Projects")! as ProjectsDoc;

      /**Data for the projects and it's Authors */
      // const authorsData = getAuthorsData()! as Author[];

      // const projectsData = getProjectsData()! as Project[]

      let authorsImagesUrls: string[] = []
      let projectImagesUrls: string[] = []
    //  for (let i = 0; i < authorsData.length; i++) {
    //    let element = (authorsData[i] as Author).pictureUrl;
    //    /**Authors profile picture*/
    //    authorsImagesUrls.push(element)
    //  }

    //  for (let i = 0; i < projectsData.length; i++) {
    //    /**Array of images that a project has */
    //    let images = [(projectsData[i] as Project).image,
    //    (projectsData[i] as Project).homeImage,
    //    (projectsData[i] as Project).content.bg_ending_image,
    //    (projectsData[i] as Project).content.introduction_image,
    //    (projectsData[i] as Project).content.sm_ending_image];
    //    projectImagesUrls.push(...images)
    //  }

      /**Images url array */
      let urls: string[] = [
        //Project images
        ...projectImagesUrls,
        //Authors ProfilePicture
        ...authorsImagesUrls,
        //nav logo
        navigationData.logoUrl,

        //Home images
        homeData.hero.heroImgUrl, //hero image
        homeData.bottomHero.heroImgUrl, //bottom hero image
        homeData.bottomHero.heroImgMobileUrl, //bottom hero mobile image
        homeData.cta.image1Url, //cta image 1
        homeData.cta.image1MobileUrl, ///cta mobile image 1
        homeData.cta.image2Url, //cta image 2
        homeData.cta.image2MobileUrl, //cta mobile image 2
        homeData.cta.image3Url, //cta  image 3
        homeData.cta.image3MobileUrl, //cta mobile image 3

        //Studio images
        studioData.hero.heroImgUrl, //hero image
        studioData.hero.heroImgMobileUrl, //hero mobile image
        studioData.channels.backgroundUrl, //channels bg image

        //Contact Us Images
        contactData.banner.ringsUrl,
        contactData.button.iconUrl,

        // //Project Overview Images
        singleCaseData.chatCard.insideIconUrl,
        singleCaseData.chatCard.cornerIconUrl,
        singleCaseData.icons.arrow,
        singleCaseData.icons.category,
        singleCaseData.icons.star
      ];

      while (urls.length > 0) {
        //While there are images needed of loading
        /**Preload reults */
        const results = await bulkPreload(urls);

        /**Successfull indexes */
        const successfullIndexes = results
          .map((res, i) => (res ? i : null))
          .filter((idx) => idx !== null);

        //Remove urls, allow to stay only those that are not in the successful indexes
        urls = urls.filter((_, i) => successfullIndexes.indexOf(i) === -1);

        // console.log(urls);
      }

      //Set all images as loaded
      setAllImagesLoaded(true);
    }
  };

  //Use effect
  useEffect(() => {
    if (!allImagesLoaded && allDocsSnap && allAuthorsSnap && allProjectsSnap) {
      //Images not laoded yet and data from firestore fetched
      //Do preload images
      preloadImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDocsSnap,allProjectsSnap,allAuthorsSnap]);

  return (
    <PreloadContext.Provider
      value={{
        getViewData,
        getProjectsData,
        getAuthorsData,
        isReadyToLoad: allDocsSnap && allImagesLoaded,
      }}
    >
      {children}
    </PreloadContext.Provider>
  );
};

export { PreloadProvider };
