//React imports
import { useContext, useEffect, useState } from "react";
//contexts
import { AnalyticsContext } from "../../context/analytics.context";
import { PreloadContext } from "../../context/preload.context";
//local imports
import Wave from "../../components/wave/wave";
import ChannelCard from "../../components/channelCard/channelCard";
import ContactCard from "../../components/contactCard/contactCard";
import Footer from "../../components/footer/footer";
import SEOHead from "../../components/seo-head/seo-head";
import { StudioDoc } from "../../interfaces/studio";
import { animeJSBaseParams } from "../../config/animejs.config";
import { Language } from "../../interfaces/general";
//hooks
import { useTranslation } from "react-i18next";
import useScreenSize from "../../hooks/useScreenSize";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations";
//css
import "./studio.view.scss";
//Assets
import Star from "../../assets/elements/star.svg";
import Seal from "../../assets/elements/seal.svg";

/**Studio view component */
const StudioView = () => {
  //Contexts
  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext);

  //Preload context
  const { getViewData, isReadyToLoad } = useContext(PreloadContext);

  //State
  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false);

  //Content
  const [content, setContent] = useState<StudioDoc | undefined>(
    getViewData("Studio") as StudioDoc | undefined
  );

  //translation hook
  const { i18n } = useTranslation();

  //Animations hook
  const { init } = useViewAnimations();

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(content.seo?.title || "Studio Brass | Home");

      //Update flag
      setPageViewSent(true);
    }
    // eslint-disable-next-line
  }, [content]);

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setContent(getViewData("Studio") as StudioDoc | undefined);
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Studio")]);

  useEffect(() => {
    //Scroll on view rendered
    if (window) window.scrollTo(0, 0);

    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //about us intro text slide in from left
        identifier: ".about-us-description-intro",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".about-us-description-intro",
          translateX: [-150, 0],
          autoplay: false,
        },
      },
      {
        //about us title and bottom paragraph slide in from left
        identifier: ".about-us-description-title",
        animationParams: {
          ...animeJSBaseParams,
          targets: [
            ".about-us-description-title",
            ".about-us-description-paragraph",
          ],
          translateX: ["-300vw", 0],
          autoplay: false,
          duration: 1000,
          delay: 300,
        },
      },
      //about us hero img slide in from right
      {
        identifier: ".about-us-hero",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: ".about-us-hero",
          translateX: ["100vw", 0],
          autoplay: false,
          duration: 1000,
        },
      },
      {
        //about us bottom paragraphs slide in from bottom
        identifier: ".about-us-details",
        viewOffset: [0, 500],
        animationParams: {
          ...animeJSBaseParams,
          targets: [".about-us-details-info", " about-us-details-info-accent"],
          translateY: [500, 0],
          autoplay: false,
          duration: 800,
        },
      },
      {
        //channels title slide in from top
        identifier: ".channels-title",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".channels-title",
          translateY: [-100, 0],
          autoplay: false,
        },
      },
      {
        //channels star icon slide in from top
        identifier: ".channels-star-icon",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".channels-star-icon",
          translateY: [-100, 0],
          autoplay: false,
        },
      },
      //channels subtitle slide in from bottom
      {
        identifier: ".channels-subtitle",
        viewOffset: [0, 150],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".channels-subtitle",
          translateY: [150, 0],
          autoplay: false,
        },
      },
    ];
    init(targets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { sizeStatus } = useScreenSize();

  return (
    <>
      {content && <SEOHead {...content.seo} />}

      <main
        className="studio-container"
        style={{ backgroundColor: content?.hero.backgroundColor }}
      >
        <div className="main-container main-container-top">
          <section className="about-us">
            <div className="about-us-description">
              <div
                className="about-us-description-intro"
                dangerouslySetInnerHTML={{
                  __html:
                    content?.hero.introductionText[i18n.language as Language] ??
                    "",
                }}
              ></div>
              <div
                className="about-us-description-title"
                dangerouslySetInnerHTML={{
                  __html: content?.hero.title[i18n.language as Language] ?? "",
                }}
              ></div>
              <div
                className="about-us-description-paragraph"
                dangerouslySetInnerHTML={{
                  __html:
                    content?.hero.subtitle[i18n.language as Language] ?? "",
                }}
              ></div>
            </div>

            <div className="about-us-hero">
              <div className="about-us-hero-picture">
                <img
                  src={
                    sizeStatus > 480
                      ? content?.hero.heroImgUrl
                      : content?.hero.heroImgMobileUrl
                  }
                  alt="hero-img"
                  className="about-us-hero-img"
                />
              </div>
              <img src={Seal} alt="seal" className="about-us-hero-seal" />
            </div>

            <hr
              style={{
                color: content?.hero.separatorsColor,
                backgroundColor: content?.hero.separatorsColor,
              }}
            />

            <div className="about-us-details">
              <div
                className="about-us-details-info about-us-details-info-accent"
                dangerouslySetInnerHTML={{
                  __html:
                    content?.hero.bottomHighlightedText[
                      i18n.language as Language
                    ] ?? "",
                }}
              ></div>
              <div
                className="about-us-details-info"
                dangerouslySetInnerHTML={{
                  __html:
                    content?.hero.bottomCenterText[i18n.language as Language] ??
                    "",
                }}
              ></div>
              <div
                className="about-us-details-info"
                dangerouslySetInnerHTML={{
                  __html:
                    content?.hero.bottomRightText[i18n.language as Language] ??
                    "",
                }}
              ></div>
            </div>
          </section>
        </div>

        <section
          className="channels"
          style={{ backgroundImage: `url(${content?.channels.backgroundUrl})` }}
        >
          <Wave
            color={content?.hero.backgroundColor || ""}
            position="TOP"
          ></Wave>
          <div className="main-container channels-container">
            <img className="channels-star-icon" src={Star} alt="star-icon" />

            <div
              className="channels-title"
              dangerouslySetInnerHTML={{
                __html:
                  content?.channels.title[i18n.language as Language] ?? "",
              }}
            ></div>

            <div
              className="channels-subtitle"
              dangerouslySetInnerHTML={{
                __html:
                  content?.channels.subtitle[i18n.language as Language] ?? "",
              }}
            ></div>

            <div className="channels-cards">
              {content?.channels.items.map((card: any, index: number) => (
                <ChannelCard cardInfo={card} key={index} index={index} />
              ))}
            </div>
          </div>
          <Wave
            color={content?.contactCard.backgroundColor || ""}
            position="BOTTOM"
          ></Wave>
        </section>
        <section className="main-container">
          <div className=" contact-card-container">
            <ContactCard cardInfo={content?.contactCard} />
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default StudioView;
