//Third parties
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//Local imports
import { routes } from "./routes/index";
//Styles
import "./App.scss";
import Navbar from "./components/navbar/navbar";
import Menu from "./components/menu/menu";
import Navigation from "./components/navigation/navigation";
import { useContext } from "react";
import { PreloadContext } from "./context/preload.context";
import Spinner from "./components/spinner/spinner";

function App() {
  //Preload
  const { isReadyToLoad } = useContext(PreloadContext);

  return isReadyToLoad ? (
    <Router>
      <Navigation />
      <Switch>
        {routes.map((route, i) => (
          <Route {...route} key={`public-routes-route-${i}`} />
        ))}
      </Switch>
    </Router>
  ) : (
    <Spinner />
  );
}

export default App;
