//React imports
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//context
import { PreloadContext } from "../../context/preload.context";
//hooks
import useScreenSize from "../../hooks/useScreenSize";
import useCurrentYear from "../../hooks/useCurrentYear";
//interface
import { FooterDoc } from "../../interfaces/footer";
import { Language } from "../../interfaces/general";
//css
import "./footer.scss";

//footer component
const Footer = () => {
  const { sizeStatus } = useScreenSize();

  const { getViewData, isReadyToLoad } = useContext(PreloadContext);

  const { i18n } = useTranslation();
  const currentYear  =useCurrentYear();

  //Data
  //Content
  const [content, setContent] = useState<FooterDoc | undefined>(
    getViewData("Footer") as FooterDoc | undefined
  );
  const footerStart = {
    en: `© ${currentYear} Studio Brass All Rights Reserved.`,
    es: `© ${currentYear} Studio Brass Todos los derechos reservados.`,
  };

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setContent(getViewData("Footer") as FooterDoc | undefined);
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Footer")]);

  return (
    <footer className="footer">
      <div
        className="privacy-text"
        dangerouslySetInnerHTML={{
          __html:footerStart[i18n.language as Language] ?? '' + content?.footerText[i18n.language as Language] ?? "",
        }}
      ></div>
      <button
        className="up-btn"
        onClick={() => {
          if (window) window.scrollTo(0, 0);
        }}
      >
        {sizeStatus <= 768
          ? "^"
          : content?.upBtn[i18n.language as Language] + "^"}
      </button>
    </footer>
  );
};

export default Footer;
