import React from "react";
import useContentData from "../../hooks/useContentData";
//hooks
import { useTranslation } from "react-i18next";
import { Language, LocalizedText } from "../../interfaces/general";
import { Project } from "../../interfaces/projects";
export const MosaicCard:React.FC<{
  proyect: Project, 
  icons:any,
  darkMode?:boolean }> = ({ proyect: proyect, icons: icons,darkMode }: any) => {

  //translation hook
  const { i18n } = useTranslation();
    const { status, data } = useContentData("Work");
    if (!data) {
      return <></>
    }
  return (
    <div className={darkMode ? "mosaicCard black-background" : "mosaicCard"}>
      <div className={darkMode ?  'border-gray-2' :'cream-border'}>
      <div className="mosaicCard-categoryContainer">
        <img className={darkMode ? "categorylogo" : "categorylogo darkMode-icon"} src={icons.category} />
        <div className={darkMode ? "mosaicCard-category color-white" : "mosaicCard-category"}>{proyect.category[i18n.language as Language]}</div>
      </div>
      <div className={darkMode ?  "mosaicCard-proyectName color-white" : "mosaicCard-proyectName"}>{proyect.name}</div>
      <img className={darkMode ? "mosaicCard-arrow darkMode-icon" :"mosaicCard-arrow"} src={icons.arrow} />
      <div className="mosaicCard-learnMore"  dangerouslySetInnerHTML={{__html:data.banner.accentedLink[i18n.language as Language] ?? ""}}>
      </div>
      <li className={darkMode ? "mosaicCard-click mosaicCard-click-dark":"mosaicCard-click"}  dangerouslySetInnerHTML={{__html:data.banner.helper[i18n.language as Language] ?? ""}}></li>
      </div>
    </div>
   
  );
};
