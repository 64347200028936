import "./wave.scss";

//Wave component, can be used on top or bottom
const Wave: React.FC<{ color: string; position: "TOP" | "BOTTOM" }> = ({
  color,
  position,
}) => {
  let pathPosition;
  if (position === "TOP") {
    pathPosition =
      "M-46.61,77.26 C199.43,35.81 308.92,36.80 560.04,88.13 L500.00,0.00 L0.00,0.00 Z";
  } else {
    pathPosition =
      "M-23.47,113.78 C145.82,82.20 332.61,79.24 541.42,122.66 L500.00,150.00 L0.00,150.00 Z";
  }

  return (
    <div
      className={
        position === "TOP"
          ? "wave-container top-wave"
          : "wave-container bottom-wave"
      }
    >
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        className="wave-svg"
      >
        <path d={pathPosition} style={{ fill: color }} />
      </svg>
    </div>
  );
};

export default Wave;
