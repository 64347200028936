//Firebase imports
import "firebase/app";
import "firebase/firestore";

/**Firebase config */
export const firebaseClientConfig = {
  apiKey: "AIzaSyB8PDniNYQQo0q3EiyMOu3zoT3a085jZJs",
  authDomain: "studio-brass.firebaseapp.com",
  databaseURL: "https://studio-brass.firebaseio.com",
  projectId: "studio-brass",
  storageBucket: "studio-brass.appspot.com",
  messagingSenderId: "397395872276",
  appId: "1:397395872276:web:df45d155c3290ebd6cb501",
  measurementId: "G-RXP5ME7TRE",
};
