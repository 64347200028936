import React from "react"
import { MosaicCard } from "./mosaicCard"
import { Link } from "react-router-dom"
import { Project } from "../../interfaces/projects"
import { animeJSBaseParams } from "../../config/animejs.config"
//hooks
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations"
export const Mosaic: React.FC<{
  project: Project
  icons: any
  darkMode?: boolean
}> = ({ project, icons, darkMode }) => {
  const { init, execute } = useViewAnimations()
  return (
    <div className="mosaic-container">
      <div  className="mosaic">
        <div
          className={`mosaic-item mosaic-item-1-${project.id} borderContainer bg-black`}
          onMouseEnter={() =>
            execute({
              ...animeJSBaseParams,
              targets: [`.mosaic-item-1-${project.id}`],
              scale: ["100%", "110%"],
              rotate: ["0deg", "0.01deg"],
            })
          }
          onMouseLeave={() =>
            execute({
              ...animeJSBaseParams,
              targets: [`.mosaic-item-1-${project.id}`],
              scale: ["110%", "100%"],
              rotate: ["0.01deg", "0deg"],
            })
          }
        >
          <img src={project.image} />{" "}
        </div>
        <div
          className={`mosaic-item mosaic-item-2-${project.id}`}
          onMouseEnter={() =>
            execute({
              ...animeJSBaseParams,
              targets: [`.mosaic-item-2-${project.id}`],
              scale: ["100%", "110%"],
              rotate: ["0deg", "0.01deg"],
            })
          }
          onMouseLeave={() =>
            execute({
              ...animeJSBaseParams,
              targets: [`.mosaic-item-2-${project.id}`],
              scale: ["110%", "100%"],
              rotate: ["0.01deg", "0deg"],
            })
          }
        >
          <Link to={"/case/" + project.id}>
          <MosaicCard proyect={project} icons={icons} darkMode={darkMode} />
          </Link>
        </div>
      </div>
    </div>
  )
}
