//Third parties
import { Redirect, RouteProps } from "react-router-dom"
import HomeView from "../views/home/home.view"
import StudioView from "../views/studio/studio.view"
import WorkView from "../views/work/work_view"
import Contact from "../views/contact/contact"
import CaseSingle from "../views/caseSingle/caseSingle"
//Lazy loaded views

/**Aether view */
//const HomeView = lazy(() => import("../views/home/home.view"));

/** Studio view*/
//const StudioView = lazy(() => import("../views/studio/studio.view"));

/** Work view*/
//const WorkView = lazy(() => import("../views/work/work_view"));

/** Contact view*/
//const ContactView = lazy(() => import("../views/contact/contact"));

/** Case Single view*/
//const CaseSingleView = lazy(() => import("../views/caseSingle/caseSingle"));

export const routes: RouteProps[] = [
  {
    path: "/",
    exact: true,
    children: <HomeView />,
  },
  {
    path: "/studio",
    exact: true,
    children: <StudioView />,
  },
  {
    path: "/work-with-us",
    exact: true,
    children: <WorkView />,
  },
  {
    path: "/contact-us",
    exact: true,
    children: <Contact />,
  },
  {
    path: "/case/:name",
    exact: true,
    children: <CaseSingle />,
  },
  {
    path: "*", //404 route
    children: (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    ),
  },
]
