import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router';
import Footer from '../../components/footer/footer';
import { Mosaic } from '../../components/mosaic/mosaic'
import Wave from '../../components/wave/wave';
import { animeJSBaseParams } from "../../config/animejs.config";
//context
import { AnalyticsContext } from "../../context/analytics.context";
import { PreloadContext } from "../../context/preload.context";

//hooks
import { useTranslation } from "react-i18next";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations";

import { Project, ProjectsDoc } from '../../interfaces/projects';
import { getEnvironment } from '../../utils/enviroment.utils';
import { Language } from '../../interfaces/general';
import { Link } from 'react-router-dom';
import { Author } from '../../interfaces/author';
import { WorkWithUsDoc } from '../../interfaces/work-with-us';
const CaseSingle = () => {

  const { name} = useParams<{name?: string}>();
    //Contexts
    //Analytics context
    const { emitPageView } = useContext(AnalyticsContext);
    //Preload context
    const { getViewData, getAuthorsData, getProjectsData, isReadyToLoad } = useContext(PreloadContext);
    //The Project displayed will be the one where the id matches the name parameter of the url
    const [projectData,setProjectData] = useState<Project | undefined>((getProjectsData() as Project[]).find((project) =>{
     return project.id === name
    }));
    const [relatedProjects,setRelatedProjects] = useState<Project[]>(getProjectsData() as Project[]);

    const [author,SetAuthor] =useState<Author>((getAuthorsData() as Author[])[0])

    const [sizeStatus, setSizeStatus] = useState(window.innerWidth);


  //State
  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false);

  //Content
  const [content, setContent] = useState<ProjectsDoc | undefined>(
    getViewData("Projects") as ProjectsDoc | undefined
  );

  const [contentWork, setContentWork] = useState<WorkWithUsDoc | undefined>(
    getViewData("Work") as WorkWithUsDoc | undefined
  );
  //translation hook
  const { i18n } = useTranslation();

  //Animations hook
  const { init, execute } = useViewAnimations();


  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {

    //Update flag
    setPageViewSent(true);
    } 
    // eslint-disable-next-line
  }, [content]);

  useEffect(() => {
    let authors = getAuthorsData() as Author[]
    //Searches for the author of the project, if there isn't it uses the first author in the array
    let currentAuthor = authors.find((author) => {
      return author.id = projectData?.authorId
    }) ?? authors[0]

    SetAuthor(currentAuthor)

  }, [projectData,setProjectData])

  useEffect(() => {
    setProjectData((getProjectsData() as Project[]).find((project) =>{
     return project.id === name
    }))
    if (window) window.scrollTo(0, 0);
  }, [name])
  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setContent(getViewData("Projects") as ProjectsDoc | undefined);
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Projects")]);
    useLayoutEffect(() => {
      function updateSizeStatus() {
        setSizeStatus(window.innerWidth);
      }
  
      window.addEventListener("resize", updateSizeStatus);
  
      updateSizeStatus();
  
      return () => window.removeEventListener("resize", updateSizeStatus);
    }, [sizeStatus]);

    useEffect(() => {
    //Scroll on view rendered
    if (window) window.scrollTo(0, 0);
      const targets: AnimationTarget[] = [
              {
        //about us header slide in from left
        identifier: ".mainCartel",
        animationParams: {
          ...animeJSBaseParams,
          targets: [
            ".mainCartel",
          ],
          translateX: ["-100vw", 0],
          autoplay: false,
          duration: 1000,
          delay: 300,
        },
      },
      {
        //about us author's capabilities slide in from left
        identifier: ".author-capabilities",
        animationParams: {
          ...animeJSBaseParams,
          targets: [
            ".author-capabilities",
          ],
          translateX: ["-100vw", 0],
          autoplay: false,
          duration: 1000,
        },
      },
      {
        //channels title slide in from top
        identifier: ".author-description",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".author-description",
          translateY: [-100, 0],
          autoplay: false,
          delay: 300
        },
      },
      //main img slide in from right
      {
        identifier: ".singleCase-main-image",
        viewOffset: [0, 100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".singleCase-main-image",
          translateX: ["5vw", 0],
          autoplay: false,
          duration: 1000,
        },
      },
      //main introduction slide in from right
      {
        identifier: ".content-intro",
        viewOffset: [0, 100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".content-intro",
          translateX: ["5vw", 0],
          autoplay: false,
          duration: 1000,
        },
      },
      //main body slides in from right
      {
        identifier: ".content-body",
        viewOffset: [0, 100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".content-body",
          translateY: [50, 0],
          autoplay: false,
          duration: 800,
        },
      },
      //main conclusion slide in from right
      {
        identifier: ".content-conclusion",
        viewOffset: [0, 100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".content-conclusion",
          translateY: [50, 0],
          autoplay: false,
          duration: 800,
        },
      },
      {
        //about us bottom paragraphs slide in from bottom
        identifier: ".singleCase-mainTopic-mainInfo-big-img",
        viewOffset: [0, 100],
        animationParams: {
          ...animeJSBaseParams,
          targets: [".singleCase-mainTopic-mainInfo-big-img"],
          translateY: [50, 0],
          autoplay: false,
          duration: 800,
        },
      },
      {
        //about us bottom paragraphs slide in from bottom
        identifier: ".about-us-details",
        viewOffset: [0, 500],
        animationParams: {
          ...animeJSBaseParams,
          targets: [".singleCase-mainTopic-mainInfo-big-img"],
          translateY: [500, 0],
          autoplay: false,
          duration: 800,
        },
      },
      {
        //work-header-description-intro slide in from top
        identifier: ".work-header-description-star",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-header-description-star",
          translateY: [-100, 0],
          autoplay: false,
          delay: 300
        },
      },
      {
        //work-header-description-intro slide in from top
        identifier: ".work-header-description-intro",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-header-description-intro",
          translateY: [-100, 0],
          autoplay: false,
          delay: 300
        },
      },
      {
        //channels title slide in from top
        identifier: ".work-header-description-title",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-header-description-title",
          translateY: [-100, 0],
          autoplay: false,
          delay: 300
        },
      },
      {
        //about us bottom paragraphs slide in from bottom
        identifier: ".work-header-description-paragraph",
        viewOffset: [0, 500],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-header-description-paragraph",
          translateY: [500, 0],
          autoplay: false,
          duration: 800,
        },
      },
      ]
      init(targets)
    }, [])
const capabilitiesTitle = {
  en: "Capabilities",
  es: "Capacidades"
}
    return (
        <div className='background-color-cream-2'>
            <section className='caseSingle-header'>
                <div className="caseSingle-mainCartel mainCartel">
                {sizeStatus <= 768 ? <div className="mainCartel-logoSide">
                      <img src="https://firebasestorage.googleapis.com/v0/b/studio-brass.appspot.com/o/Elements%2FSVGs%2Fuiux.svg?alt=media&token=b5d48ada-151c-4fe2-a6f0-a484014a9175" alt="UX/UI" className="caseSingle-mainCartel-typelogo" />
                      <div className="mainCartel-logoSide-subtitle" dangerouslySetInnerHTML={{__html:projectData?.category[i18n.language as Language] ?? ""}}>
                        
                      </div>
                    </div> : <div></div>}
                    <div className="mainCartel-mainSide">
                      <div className="mainCartel-mainSide-title">
                    <div className="mainCartel-mainSide-intro"  dangerouslySetInnerHTML={{__html:projectData?.subtitle[i18n.language as Language] ?? ""}}>
                    </div>
                    <div className="mainCartel-mainSide-title-title"  dangerouslySetInnerHTML={{__html:projectData?.name ?? ""}}>
                    </div>
                    </div>
                    <div className="mainCartel-mainSide-text"  dangerouslySetInnerHTML={{__html:projectData?.abstract[i18n.language as Language] ?? ""}}>
                    </div>
                    </div>
                    {sizeStatus > 768 ? <div className="mainCartel-logoSide">
                      <img src="https://firebasestorage.googleapis.com/v0/b/studio-brass.appspot.com/o/Elements%2FSVGs%2Fuiux.svg?alt=media&token=b5d48ada-151c-4fe2-a6f0-a484014a9175" alt="UX/UI" className="caseSingle-mainCartel-typelogo" />
                      <div className="mainCartel-logoSide-subtitle">
                        UX/UI
                      </div>
                    </div> : <div></div>}
                </div>
                <h1></h1>
            <div className='singleCase-main-image'>
                <img src={projectData?.homeImage} alt="Main image" />
            </div>
            </section>
            <div className="background-gradient-black .z-index-minus-1">
          <Wave color="#fffaf3" position="TOP"/>
          <section className='singleCase-mainTopic'>
              <section className="singleCase-mainTopic-sideInfo">
                <div className="singleCase-mainTopic-sideInfo-impactfullMessage color-white" dangerouslySetInnerHTML={{__html: author.description[i18n.language as Language]??""}}></div>
                <div className="singleCase-mainTopic-sideInfo-authorsInfo authorsInfo-top">
                    <div className="author-capabilities color-white">
                            <div dangerouslySetInnerHTML={{__html: capabilitiesTitle[i18n.language as Language] ?? ""}}></div>
                        <ul className='color-accent skill-list'>
                            {author.capabilities[i18n.language as Language] ? author.capabilities[i18n.language as Language].map((capability:string,i) =>{
                              return <li itemID={i.toString()} id={i.toString()}>{capability}</li>
                            }) : ""}
                        </ul>
                    {sizeStatus > 768 ?
                    <>
                    <img src="https://firebasestorage.googleapis.com/v0/b/studio-brass.appspot.com/o/Images%2FWork%2Fprofile%20pic.PNG?alt=media&token=47ddd137-289a-47ea-bf6f-9c206e2a5649" alt="Author" className="authors-profilePic" />
                    <div className="author-description">
                        <div className="author-description-name color-white" dangerouslySetInnerHTML={{__html: author.name}} ></div>
                        <div className="author-description-job color-accent skill-list" dangerouslySetInnerHTML={{__html: author.job[i18n.language as Language] ?? ""}}></div>
                    </div>
                    <div className="author-quote">
                      <div className="author-quote-vline">
                      </div>
                      <div>
                    <span dangerouslySetInnerHTML={{__html: author.quote[i18n.language as Language] ?? ''}}>
                    </span>
                    </div>
                    </div>
                    </> : <div></div>
                    }
                </div>
                </div>
              </section>
              <section className="singleCase-mainTopic-mainInfo color-text-2">
              {sizeStatus <= 768 ? <hr /> : <div></div>}
                  <div  dangerouslySetInnerHTML={{__html:projectData?.content.intro[i18n.language as Language] ?? ""}} className="content-intro"></div>
                  <img src={projectData?.content.introduction_image} alt="Case 1" className='singleCase-mainTopic-mainInfo-big-img' />
                  <div  dangerouslySetInnerHTML={{__html:projectData?.content.body[i18n.language as Language] ?? ""}} className="content-body"></div>
                  <div className="singleCase-mainTopic-mainInfo-doubleImages">
                    <div className="doubleImages-image">
                      <img src={projectData?.content.bg_ending_image} alt="" />
                    </div>
                    <div className="doubleImages-image">
                      <img src={projectData?.content.sm_ending_image} alt="" />
                    </div>
                  </div>
                  <div className="singleCase-mainTopic-mainInfo-quote color-white">
                  <div className="author-quote-vline">
                      </div>
                    <div className='content-quote quote-info'  dangerouslySetInnerHTML={{__html:projectData?.content.quote[i18n.language as Language] ?? ""}}></div>
                  </div>
                  <div  dangerouslySetInnerHTML={{__html:projectData?.content.conclusion[i18n.language as Language] ?? ""}} className="content-conclusion"></div>
              {sizeStatus < 768 ? <hr /> : <div></div>}
              </section>
          </section>
          {sizeStatus <= 768 ?
                    <div>
                    <div className='singleCase-mainTopic-sideInfo-authorsInfo'>
                    <img src="https://firebasestorage.googleapis.com/v0/b/studio-brass.appspot.com/o/Images%2FWork%2Fprofile%20pic.PNG?alt=media&token=47ddd137-289a-47ea-bf6f-9c206e2a5649" alt="Author" className="authors-profilePic" />
                    <div className="author-description">
                        <div className="author-description-name color-white" dangerouslySetInnerHTML={{__html:author.name ?? ''}}>
                        </div>
                        <div className="author-description-job color-accent skill-list" dangerouslySetInnerHTML={{__html:author.job[i18n.language as Language] ?? ''}}>
                        </div>
                    </div>
                    </div>
                    <div className="author-quote">
                    <span dangerouslySetInnerHTML={{__html:author.quote[i18n.language as Language] ?? ''}}>

                    </span>
                    
                    </div>
                    </div>
                     : <div></div>
                    }
        <section className="main-container">
          <div className=" contact-card-container background-black">
          </div>
        </section>
          <Wave color="#fffaf3" position="BOTTOM"/>
        </div>
        <div className='work-header case-work-header'>
        <div className="work-header-description py-4">
            <img
              className="work-header-description-star"
              src={contentWork?.icons.star}
              alt="star-icon"
              />
            <div className="work-header-description-intro" dangerouslySetInnerHTML={{__html: content?.relatedSection.intro[i18n.language as Language] ?? ""}}></div>
            <div className="work-header-description-title" dangerouslySetInnerHTML={{__html: content?.relatedSection.title[i18n.language as Language] ?? ""}}></div>
            <div className="work-header-description-paragraph" dangerouslySetInnerHTML={{__html: content?.relatedSection.text[i18n.language as Language] ?? ""}}></div>
          </div>
        </div>
        <div className="grid doubleCol">
            {relatedProjects.map((project,index)=>{
              if (index<2) {
                return <Mosaic project={project} icons={contentWork?.icons} darkMode={true} />
              }
            })}
        </div>
        <Link to='/work-with-us' className="case-circle-link-container">
          <div className="case-circle"
          onMouseEnter={() =>
            execute({
              ...animeJSBaseParams,
              targets: ['.case-circle'],
              scale: ["100%", "110%"],
              rotate: ["0deg", "0.01deg"],
            })
          }
          onMouseLeave={() =>
            execute({
              ...animeJSBaseParams,
              targets:['.case-circle'],
              scale: ["110%", "100%"],
              rotate: ["0.01deg", "0deg"],
            })
          }
      >
            <img
                src="https://firebasestorage.googleapis.com/v0/b/studio-brass.appspot.com/o/Elements%2FSVGs%2Farrow.svg?alt=media&token=ed4b95c7-a752-470c-bb68-667d892443a9"
                alt="arrow"
                className="case-arrow-icon"
            />
            <div className="case-circle-text" dangerouslySetInnerHTML={{__html: "ALL PROJECTS"}}></div>

            </div>
            </Link>
            <Footer/>
        </div>
    )
}

export default CaseSingle;
