//React imports
import React, { useEffect } from "react"
//Third parties imports
import { Link } from "react-router-dom"
import { animeJSBaseParams } from "../../config/animejs.config"
//hooks
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations"
//interface
import { selectedCase } from "../../interfaces/home"
//css
import "./casesMosaic.scss"
//assets
import Arrow from "../../assets/elements/arrow.svg"
import { useTranslation } from "react-i18next"
import { Language } from "../../interfaces/general"

//Case mosaic component, used on home view
const CasesMosaic = ({
  sizeStatus,
  caseData,
  index,
}: {
  sizeStatus: number
  caseData: selectedCase
  index: number
}) => {
  let bg = ""

  //bg is not assigned to the first one because it includes the section title, it is in home view
  if (index === 1 || index === 2) {
    bg = caseData.backgroundUrl
  }

  //hooks
  const { init, execute } = useViewAnimations()

  const { i18n } = useTranslation()

  useEffect(() => {
    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //case 1 mosaic top left  img slide in from left
        identifier: "#mosaic-0-img-1",
        viewOffset: [0, -150],
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: ["#mosaic-0-img-1"],
          translateX: ["-100vw", 0],
          duration: 700,
          autoplay: false,
        },
      },
      {
        //case 1 mosaic  top right img slide in from right
        identifier: "#mosaic-0-img-2",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: "#mosaic-0-img-2",
          translateX: ["100vw", 0],
          autoplay: false,
          duration: 700,
        },
      },
      {
        //case 1 mosaic bottom left img slide in from right
        identifier: "#mosaic-0-img-3",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: "#mosaic-0-img-3",
          translateX: ["-100vw", 0],
          autoplay: false,
          duration: 700,
        },
      },
      //case 1 mosaic card slide in from bottom
      {
        identifier: "#mosaic-0-card-container",
        viewOffset: [0, 500],
        animationParams: {
          ...animeJSBaseParams,
          targets: "#mosaic-0-card-container",
          translateY: [500, 0],
          autoplay: false,
        },
      },
      {
        identifier: "#mosaic-1-img-1",
        viewOffset: [0, -150],
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: ["#mosaic-1-img-1"],
          translateX: ["100vw", 0],
          duration: 700,
          autoplay: false,
        },
      },
      {
        //case 2 mosaic top left  img slide in from left
        identifier: "#mosaic-1-img-2",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: "#mosaic-1-img-2",
          translateX: ["-100vw", 0],
          autoplay: false,
          duration: 700,
        },
      },
      {
        //case 2 mosaic top right img slide in from right
        identifier: "#mosaic-1-img-3",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: "#mosaic-1-img-3",
          translateX: ["100vw", 0],
          autoplay: false,
          duration: 700,
        },
      },
      //case 2 mosaic card slide in from bottom
      {
        identifier: "#mosaic-1-card-container",
        viewOffset: [0, 500],
        animationParams: {
          ...animeJSBaseParams,
          targets: "#mosaic-1-card-container",
          translateY: [500, 0],
          autoplay: false,
        },
      },
      //case 3 mosaic top left img slide in from left
      {
        identifier: "#mosaic-2-img-1",
        viewOffset: [0, -150],
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: ["#mosaic-2-img-1"],
          translateX: ["-100vw", 0],
          duration: 700,
          autoplay: false,
        },
      },
      {
        //case 3 mosaic top right img slide in from right
        identifier: "#mosaic-2-img-2",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: "#mosaic-2-img-2",
          translateX: ["100vw", 0],
          autoplay: false,
          duration: 700,
        },
      },
      {
        //case 3 mosaic bottom left  img slide in from left
        identifier: "#mosaic-2-img-3",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: "#mosaic-2-img-3",
          translateX: ["-100vw", 0],
          autoplay: false,
          duration: 700,
        },
      },
      //case 3 mosaic card slide in from bottom
      {
        identifier: "#mosaic-2-card-container",
        viewOffset: [0, 500],
        animationParams: {
          ...animeJSBaseParams,
          targets: "#mosaic-2-card-container",
          translateY: [500, 0],
          autoplay: false,
        },
      },
    ]

    init(targets)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        style={{ backgroundImage: `url(${bg})`, backgroundSize: "100% 100%" }}
        className={
          index === 1
            ? "case-mosaic case-mosaic-odd main-container"
            : "case-mosaic main-container"
        }
      >
        <div
          className={
            index === 1
              ? `mosaic-sec  mosaic-sec-1 mosaic-sec-2-odd`
              : "mosaic-sec  mosaic-sec-1"
          }
        >
          <div
            className={
              index === 1 ? " case-number case-number-odd" : "case-number"
            }
          >
            0{index + 1}
          </div>
          <div
            className={
              index === 1
                ? `mosaic-img-container-1 img-border  mosaic-img-container-1-odd`
                : `mosaic-img-container-1 img-border`
            }
            id={`mosaic-${index}-img-1`}
          >
            <img
              className="mosaic-img mosaic-img-1"
              src={caseData.image1Url}
              alt=""
            />
          </div>
        </div>
        <div
          className={
            index === 1
              ? "mosaic-sec mosaic-sec-2   mosaic-sec-1-odd"
              : "mosaic-sec mosaic-sec-2"
          }
        >
          <div
            className={
              index === 1
                ? `mosaic-img-container-2 img-border mosaic-img-container-2-odd`
                : ` mosaic-img-container-2 img-border`
            }
            id={`mosaic-${index}-img-2`}
          >
            <img
              className="mosaic-img mosaic-img-2"
              src={
                sizeStatus <= 768
                  ? caseData.mobileImage2Url
                  : caseData.image2Url
              }
              alt=""
            />
          </div>
        </div>

        <div
          className={
            index === 1
              ? "mosaic-sec  mosaic-sec-3  mosaic-sec-4-odd"
              : "mosaic-sec  mosaic-sec-3"
          }
        >
          <div
            className={
              index === 1
                ? ` mosaic-img-container-3 img-border img-border mosaic-img-container-3-odd`
                : ` mosaic-img-container-3 img-border`
            }
            id={`mosaic-${index}-img-3`}
          >
            <img
              className="mosaic-img mosaic-img-3"
              src={caseData.image3Url}
              alt=""
            />
          </div>
        </div>

        <div
          className={
            index === 1 ? "mosaic-sec mosaic-sec-3-odd" : "mosaic-sec  "
          }
        >
          <div
            className={
              index === 1
                ? `mosaic-${index}-card-container mosaic-card-container  mosaic-card-container-odd`
                : `mosaic-${index}-card-container mosaic-card-container `
            }
            id={`mosaic-${index}-card-container`}
          >
            <div
              className="case-card-border"
              onMouseEnter={() =>
                execute({
                  ...animeJSBaseParams,
                  targets: `.case-card-border`,
                  scale: ["100%", "105%"],
                  rotate: ["0deg", "0.01deg"],
                })
              }
              onMouseLeave={() =>
                execute({
                  ...animeJSBaseParams,
                  targets: `.case-card-border`,
                  scale: ["105%", "100%"],
                  rotate: ["0.01deg", "0deg"],
                })
              }
            >
              <div className="case-card">
                <div className="case-card-category">
                  <img
                    src={caseData.categoryIconUrl}
                    alt="icon"
                    className="category-icon"
                  />
                  <div
                    className="category-text"
                    dangerouslySetInnerHTML={{
                      __html:
                        caseData.category[i18n.language as Language] ?? "",
                    }}
                  ></div>
                </div>
                <div
                  className="case-card-title"
                  dangerouslySetInnerHTML={{
                    __html: caseData.name?.[i18n.language as Language] ?? "",
                  }}
                ></div>
                <div
                  className="case-card-text"
                  dangerouslySetInnerHTML={{
                    __html:
                      caseData.description[i18n.language as Language] ?? "",
                  }}
                ></div>
                <Link to={caseData.redirection.route}>
                  <img
                    src={Arrow}
                    alt="arrow"
                    className="case-card-arrow-icon"
                  />

                  <div className="case-card-bottom">
                    {caseData.redirection.name[i18n.language as Language]}
                  </div>
                </Link>
                {caseData.redirection?.sub?.[i18n.language as Language] && (
                  <li className="case-card-click">
                    {caseData.redirection?.sub?.[i18n.language as Language]}
                  </li>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CasesMosaic
