//React imports
import { useContext, useEffect, useState } from "react";
//Third parties imports
import { Link } from "react-router-dom";
//contexts
import { AnalyticsContext } from "../../context/analytics.context";
import { PreloadContext } from "../../context/preload.context";
//Local imports
import CasesMosaic from "../../components/casesMosaic/casesMosaic";
import ContactCard from "../../components/contactCard/contactCard";
import Footer from "../../components/footer/footer";
import Wave from "../../components/wave/wave";
import SEOHead from "../../components/seo-head/seo-head";
import { HomeDoc, selectedCase } from "../../interfaces/home";
import { animeJSBaseParams } from "../../config/animejs.config";
import { Language } from "../../interfaces/general";
//hooks
import useScreenSize from "../../hooks/useScreenSize";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations";
import { useTranslation } from "react-i18next";
//css
import "./home.view.scss";
//assets
import Brush1 from "../../assets/home/brush-1.png";
import Brush2 from "../../assets/home/brush-2.png";
import Lines from "../../assets/home/lines-1.svg";
import Arrow from "../../assets/elements/arrow.svg";
import Star from "../../assets/elements/star.svg";

/**Home view component */
const HomeView = () => {
  //Contexts
  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext);

  //Preload context
  const { getViewData, isReadyToLoad } = useContext(PreloadContext);

  //Hooks
  const { sizeStatus } = useScreenSize();

  const { i18n } = useTranslation();

  //Animations hook
  const { init, execute } = useViewAnimations();

  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false);

  //Content
  const [content, setContent] = useState<HomeDoc | undefined>(
    getViewData("Home") as HomeDoc | undefined
  );

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home data fetched and page view not sent yet
      emitPageView(content.seo?.title || "Studio Brass | Home");

      //Update flag
      setPageViewSent(true);
    }
    // eslint-disable-next-line
  }, [content]);

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setContent(getViewData("Home") as HomeDoc | undefined);
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Home")]);

  useEffect(() => {
    //Scroll on view rendered
    if (window) window.scrollTo(0, 0);

    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //hero intro text (top left text) slide in from left
        identifier: ".hero-intro-text",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".hero-intro-text",
          translateX: [-150, 0],
          autoplay: false,
        },
      },
      {
        //hero title slide in from left
        identifier: ".hero-title",
        animationParams: {
          ...animeJSBaseParams,
          targets: [".hero-title", ".hero-circle-link-container"],
          duration: 1000,
          delay: 300,
          translateX: ["-300vw", 0],
          autoplay: false,
        },
      },
      //hero img with brushes and lines slide in from bottom
      {
        identifier: ".hero-title",
        viewOffset: [0, -250],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".hero-img-sec",
          translateY: [250, 0],
          duration: 800,
          autoplay: false,
        },
      },
      {
        //hero bottom text slide in from bottom
        identifier: ".hero-bottomText",
        viewOffset: [0, 500],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".hero-bottomText",
          translateY: [500, 0],
          autoplay: false,
        },
      },
      //bottom hero img slide in from right
      {
        identifier: ".hero-bottom-img-container",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: ".hero-bottom-img-container",
          translateX: ["100vw", 0],
          autoplay: false,
          duration: 800,
        },
      },
      //bottom hero text slide in form bottom
      {
        identifier: ".hero-bottom-text",
        midPointOffset: 0.1,
        viewOffset: [0, 200],
        animationParams: {
          ...animeJSBaseParams,
          targets: [".hero-bottom-text-highlight", ".hero-bottom-text"],
          translateY: [200, 0],
          autoplay: false,
          duration: 800,
        },
      },
      //bottom circle slide in from right
      {
        identifier: ".hero-bottom-circle",
        midPointOffset: 0.1,
        animationParams: {
          targets: ".hero-bottom-circle",
          translateX: ["100vw", 0],
          autoplay: false,
          duration: 1600,
          ...animeJSBaseParams,
        },
      },
      {
        // cases star icon slide in from top
        identifier: ".cases-star-icon",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".cases-star-icon",
          translateY: [-100, 0],
          autoplay: false,
        },
      },
      {
        // cases title icon slide in from top
        identifier: ".cases-title",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".cases-title",
          translateY: [-100, 0],
          autoplay: false,
        },
      },
      // cases subtitle icon slide in from bottom
      {
        identifier: ".cases-subtitle",
        viewOffset: [0, 150],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".cases-subtitle",
          translateY: [150, 0],
          autoplay: false,
        },
      },
      // cta top left img slide in from left
      {
        identifier: ".cta-top-left-img",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: ".cta-top-left-img",
          translateX: ["-100vw", 0],
          autoplay: false,
          duration: 800,
        },
      },
      // cta bottom left img slide in from left

      {
        identifier: ".cta-bottom-left-img",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: ".cta-bottom-left-img",
          translateX: ["-100vw", 0],
          autoplay: false,
          duration: 800,
        },
      },
      // cta bottom right img slide in from right

      {
        identifier: ".cta-bottom-right-img",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: ".cta-bottom-right-img",
          translateX: ["100vw", 0],
          autoplay: false,
          duration: 800,
        },
      },
      // cta circle link slide in from right
      {
        identifier: ".cta-circle-link-container",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: ".cta-circle-link-container",
          translateX: ["100vw", 0],
          autoplay: false,
          duration: 1200,
        },
      },
      // cta title slide in from right
      {
        identifier: ".cta-title",
        midPointOffset: 0.1,
        animationParams: {
          ...animeJSBaseParams,
          targets: ".cta-title",
          translateX: ["100vw", 0],
          autoplay: false,
          duration: 900,
        },
      },
    ];
    init(targets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
{/* <<<<<<< HEAD
      {content && <SEOHead {...content.seo} />}

=======
      <Helmet
        title={data.seo.title}
        htmlAttributes={{ lang: "en" }}
        meta={[
          {
            name: `description`,
            content: data.seo.description,
          },
          {
            name: `keywords`,
            content: data.seo.keywords,
          },
          {
            property: "og:title",
            content: data.seo.og.title,
          },
          {
            property: "og:description",
            content: data.seo.og.description,
          },
          {
            property: "og:image",
            content: data.seo.og.image,
          },
          {
            property: "twitter:title",
            content: data.seo.twitter.title,
          },
          {
            property: "twitter:description",
            content: data.seo.twitter.description,
          },
          {
            property: "twitter:image",
            content: data.seo.twitter.image,
          },
        ]}
      />
>>>>>>> master */}
      <main className="home-container">
        <section
          className="hero-container"
          style={{ backgroundColor: content?.hero.backgroundColor }}
        >
          <div className="rectangle"></div>
          <div className="main-container main-container-top">
            <div className="hero">
              <div className="hero-title-sec">
                <div
                  className="hero-intro-text"
                  dangerouslySetInnerHTML={{
                    __html:
                      content?.hero.introductionText[
                        i18n.language as Language
                      ] ?? "",
                  }}
                ></div>
                <div
                  className="hero-title"
                  dangerouslySetInnerHTML={{
                    __html:
                      content?.hero.title[i18n.language as Language] ?? "",
                  }}
                  onClick={() => {
                    i18n.changeLanguage("es");
                  }}
                ></div>
                <div className="hero-circle-link-container" id={`hero-circle`}>
                  <Link
                    to={
                      content?.hero.redirection.route
                        ? content?.hero.redirection.route
                        : ""
                    }
                    style={{ color: "inherit" }}
                    onMouseEnter={() =>
                      execute({
                        ...animeJSBaseParams,
                        targets: `#hero-circle`,
                        scale: ["100%", "110%"],
                        rotate: ["0deg", "0.01deg"],
                      })
                    }
                    onMouseLeave={() =>
                      execute({
                        ...animeJSBaseParams,
                        targets: `#hero-circle`,
                        scale: ["110%", "100%"],
                        rotate: ["0.01deg", "0deg"],
                      })
                    }
                  >
                    <img src={Arrow} alt="arrow" className="hero-arrow-icon" />
                    <div className="hero-circle-text">
                      <div className="hero-circle-cases">
                        {
                          content?.hero.redirection?.name[
                            i18n.language as Language
                          ]
                        }
                      </div>
                    </div>

                    <div className="circle"></div>
                  </Link>
                </div>
                <div
                  className="hero-bottomText"
                  dangerouslySetInnerHTML={{
                    __html:
                      content?.hero.bottomText[i18n.language as Language] ?? "",
                  }}
                ></div>
              </div>
              <div className="hero-img-sec">
                <img
                  src={content?.hero.heroImgUrl}
                  alt="arrow"
                  className="hero-img"
                />
                <img src={Lines} alt="lines" className="hero-lines" />
                <img src={Brush1} alt="brush-1" className="brush-1" />
                <img src={Brush2} alt="brush-2" className="brush-2" />
                <div
                  className="hero-container-glass"
                  dangerouslySetInnerHTML={{
                    __html:
                      content?.hero.rightText[i18n.language as Language] ?? "",
                  }}
                ></div>
              </div>
            </div>
            <div className="hero-bottom">
              <div className="hero-bottom-img-container">
                <img
                  src={
                    sizeStatus <= 768
                      ? content?.bottomHero.heroImgMobileUrl
                      : content?.bottomHero.heroImgUrl
                  }
                  alt="bottom-hero-img"
                  className="bottom-hero-img"
                />
              </div>

              <div className="hero-bottom-text-container">
                <div
                  className="hero-bottom-text-highlight"
                  dangerouslySetInnerHTML={{
                    __html:
                      content?.bottomHero.highlightedText[
                        i18n.language as Language
                      ] ?? "",
                  }}
                ></div>
                <div
                  className="hero-bottom-text"
                  dangerouslySetInnerHTML={{
                    __html:
                      content?.bottomHero.centerParagraph[
                        i18n.language as Language
                      ] ?? "",
                  }}
                ></div>
                <div
                  className="hero-circle-link-container hero-bottom-circle"
                  id="bottom-hero-circle"
                >
                  <Link
                    to={
                      content?.bottomHero.redirection.route
                        ? content?.bottomHero.redirection.route
                        : ""
                    }
                    style={{ color: "inherit" }}
                    onMouseEnter={() =>
                      execute({
                        ...animeJSBaseParams,
                        targets: `#bottom-hero-circle`,
                        scale: ["100%", "110%"],
                        rotate: ["0deg", "0.01deg"],
                      })
                    }
                    onMouseLeave={() =>
                      execute({
                        ...animeJSBaseParams,
                        targets: `#bottom-hero-circle`,
                        scale: ["110%", "100%"],
                        rotate: ["0.01deg", "0deg"],
                      })
                    }
                  >
                    <img src={Arrow} alt="arrow" className="hero-arrow-icon" />
                    <div className="hero-circle-text">
                      <div
                        className="hero-circle-cases"
                        dangerouslySetInnerHTML={{
                          __html:
                            content?.bottomHero.redirection.name[
                              i18n.language as Language
                            ] ?? "",
                        }}
                      ></div>
                      {content?.bottomHero.redirection?.sub![
                        i18n.language as Language
                      ] && (
                        <li>
                          {
                            content?.bottomHero.redirection?.sub![
                              i18n.language as Language
                            ]
                          }
                        </li>
                      )}
                    </div>
                    <div className="circle"></div>
                  </Link>
                </div>
              </div>
              <hr
                style={{
                  backgroundColor: content?.bottomHero.separatorsColor,
                }}
              />
            </div>
          </div>
        </section>

        <section
          className="cases"
          style={{
            backgroundImage: `url(${content?.cases.selectedCases[0].backgroundUrl})`,
            backgroundPosition: "top",
            backgroundSize: "auto auto",
          }}
        >
          <Wave color={content!.hero.backgroundColor} position="TOP" />

          <div className="cases-container   ">
            <div className="main-container cases-top">
              <div className="cases-icon">
                <img src={Star} alt="star" className="cases-star-icon" />
              </div>
              <div
                className="cases-title"
                dangerouslySetInnerHTML={{
                  __html: content?.cases.title[i18n.language as Language] ?? "",
                }}
              ></div>
              <div
                className="cases-subtitle"
                dangerouslySetInnerHTML={{
                  __html:
                    content?.cases.subtitle[i18n.language as Language] ?? "",
                }}
              ></div>
            </div>

            <div className="cases-mosaic-container">
              {content?.cases.selectedCases.map(
                (caseData: selectedCase, index: number) => (
                  <CasesMosaic
                    caseData={caseData}
                    sizeStatus={sizeStatus}
                    key={index}
                    index={index}
                  />
                )
              )}
            </div>
          </div>
          <Wave color={content!.hero.backgroundColor} position="BOTTOM" />
        </section>
        <section className="cta main-container">
          <div className="cta-col-1">
            <img src={Brush1} alt="arrow" className="brush-1" />
            <div className="cta-row-1-1">
              <div className="cta-top-left-img img-border">
                <img
                  className="cta-img"
                  src={
                    sizeStatus > 768
                      ? content?.cta.image1Url
                      : content?.cta.image1MobileUrl
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="cta-row-1-2">
              <div className="cta-bottom-left-img img-border">
                <img
                  className="cta-img"
                  src={
                    sizeStatus > 768
                      ? content?.cta.image2Url
                      : content?.cta.image2MobileUrl
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="cta-col-2">
            <div className="cta-row-2-1">
              <div
                className="cta-title"
                dangerouslySetInnerHTML={{
                  __html: content?.cta.title[i18n.language as Language] ?? "",
                }}
              ></div>
              <div
                className="cta-circle-link-container"
                onMouseEnter={() =>
                  execute({
                    ...animeJSBaseParams,
                    targets: `.cta-circle-link-container`,
                    scale: ["100%", "110%"],
                    rotate: ["0deg", "0.01deg"],
                  })
                }
                onMouseLeave={() =>
                  execute({
                    ...animeJSBaseParams,
                    targets: `.cta-circle-link-container`,
                    scale: ["110%", "100%"],
                    rotate: ["0.01deg", "0deg"],
                  })
                }
              >
                <Link
                  to={content!.cta.redirection.route}
                  style={{ color: "inherit" }}
                >
                  <img src={Arrow} alt="arrow" className="cta-arrow-icon" />
                  <div className="cta-circle-text">
                    <div>
                      {content?.cta.redirection.name[i18n.language as Language]}
                    </div>
                    {content?.cta.redirection.sub![
                      i18n.language as Language
                    ] && (
                      <li>
                        {
                          content?.cta.redirection.sub![
                            i18n.language as Language
                          ]
                        }
                      </li>
                    )}
                  </div>
                  <div className="circle"></div>
                </Link>
              </div>

              <hr style={{ backgroundColor: content?.cta.separatorsColor }} />
            </div>
            <div className="cta-row-2-2">
              <div className="cta-bottom-right-img img-border">
                <img
                  className="cta-img"
                  src={
                    sizeStatus > 768
                      ? content?.cta.image3Url
                      : content?.cta.image3MobileUrl
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <Wave
          color={
            content?.contactCard.sectionBackgroundColor
              ? content?.contactCard.sectionBackgroundColor
              : ""
          }
          position="BOTTOM"
        />
        <div
          className="contactCard-container"
          style={{
            backgroundColor: content?.contactCard.sectionBackgroundColor,
          }}
        >
          <div className="main-container">
            <ContactCard cardInfo={content?.contactCard} />
          </div>
          <Footer />
        </div>
      </main>
    </>
  );
};

export default HomeView;
