//react imports
import React, { useEffect } from "react";
//Third parties imports
import { Link } from "react-router-dom";
import { animeJSBaseParams } from "../../config/animejs.config";
//hooks
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations";
//css
import "./contactCard.scss";
//Assets
import Arrow from "../../assets/elements/arrow.svg";
import Seal from "../../assets/elements/seal.svg";
import { useTranslation } from "react-i18next";

import { Language } from "../../interfaces/general";

//contact card used on many views, like home, studio, cases
const ContactCard = ({
  cardInfo,
  darkMode,
}: {
  cardInfo: any;
  darkMode?: boolean;
}) => {
  const { init, execute } = useViewAnimations();

  const { i18n } = useTranslation();

  useEffect(() => {
    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //contact card slide in from bottom
        identifier: ".contact-card",
        viewOffset: [0, 300],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".contact-card",
          translateY: [300, 0],
          duration: 900,
          autoplay: false,
        },
      },
    ];
    init(targets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={darkMode ? "contact-card black-background" : "contact-card"}
      style={{
        backgroundColor: cardInfo.backgroundColor,
        borderColor: cardInfo.borderColor,
      }}
    >
      <div
        className="contact-card-info"
        style={{
          borderColor: cardInfo.borderColor,
        }}
      >
        <img src={Seal} alt="seal" className="contact-card-seal" />
        <div
          className={
            !darkMode
              ? "contact-card-introduction"
              : "contact-card-introduction color-white"
          }
          dangerouslySetInnerHTML={{
            __html: cardInfo.introductionText[i18n.language as Language],
          }}
        ></div>
        <div
          className={
            !darkMode ? "contact-card-title" : "contact-card-title color-white"
          }
          dangerouslySetInnerHTML={{
            __html: cardInfo.title[i18n.language as Language],
          }}
        ></div>
        <div
          className={
            !darkMode
              ? "contact-card-invitation"
              : "contact-card-invitation color-white"
          }
          dangerouslySetInnerHTML={{
            __html: cardInfo.subtitle[i18n.language as Language],
          }}
        ></div>
      </div>
      <div
        className="contact-card-chat-section"
        onMouseEnter={() =>
          execute({
            ...animeJSBaseParams,
            targets: `.contact-card-arrow-icon`,
            scale: ["100%", "110%"],
            rotate: ["0deg", "0.01deg"],
          })
        }
        onMouseLeave={() =>
          execute({
            ...animeJSBaseParams,
            targets: `.contact-card-arrow-icon`,
            scale: ["110%", "100%"],
            rotate: ["0.01deg", "0deg"],
          })
        }
      >
        <Link to={cardInfo.redirection.route}>
          <img className="contact-card-arrow-icon" src={Arrow} alt="arrow" />
          <div
            className="contact-card-chat-title"
            dangerouslySetInnerHTML={{
              __html: cardInfo.redirection.name[i18n.language as Language],
            }}
          ></div>
          <div className="contact-card-chat-subtitle">
            {cardInfo.redirection.sub[i18n.language as Language] && (
              <li>{cardInfo.redirection.sub[i18n.language as Language]}</li>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ContactCard;
