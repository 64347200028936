//react imports
import React, { useContext, useEffect, useState } from "react";
//context
import { PreloadContext } from "../../context/preload.context";
//hooks
import useScreenSize from "../../hooks/useScreenSize";
//interface
import { NavigationDoc } from "../../interfaces/navigation";
//local imports
import Menu from "../menu/menu";
import Navbar from "../navbar/navbar";

//navigation component, menu and navbar
const Navigation = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const { sizeStatus } = useScreenSize();

  const { getViewData, isReadyToLoad } = useContext(PreloadContext);

  //Data
  //Content
  const [content, setContent] = useState<NavigationDoc | undefined>(
    getViewData("Navigation") as NavigationDoc | undefined
  );

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setContent(getViewData("Navigation") as NavigationDoc | undefined);
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Navigation")]);

  const showMenu = () => {
    return setActiveMenu(!activeMenu);
  };

  return (
    <>
      <Navbar showMenu={showMenu} activeMenu={activeMenu} />
      <Menu
        data={content!}
        activeMenu={activeMenu}
        sizeStatus={sizeStatus}
        showMenu={showMenu}
      />
    </>
  );
};

export default Navigation;
