//react imports
import React, { useEffect } from "react";
//locals imports
import { animeJSBaseParams } from "../../config/animejs.config";
import { Channel } from "../../interfaces/studio";
//hooks
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations";
//css
import "./channelCard.scss";
import { useTranslation } from "react-i18next";
import { Language } from "../../interfaces/general";

//channel card component used on studio view
const ChannelCard: React.FC<{ cardInfo: Channel; index: number }> = ({
  cardInfo,
  index,
}) => {
  //Animations hook
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { init, execute } = useViewAnimations();

  const { i18n } = useTranslation();

  useEffect(() => {
    //Scroll on view rendered
    if (window) window.scrollTo(0, 0);

    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        //Accomplishments title slide in from top
        identifier: "#channel-card-0",
        midPointOffset: 0.5,
        animationParams: {
          ...animeJSBaseParams,
          targets: "#channel-card-0",
          translateX: ["-100vw", 0],
          autoplay: false,
        },
      },
      {
        //Accomplishments title slide in from top
        identifier: "#channel-card-1",
        midPointOffset: 0.5,
        animationParams: {
          ...animeJSBaseParams,
          targets: "#channel-card-1",
          translateX: ["100vw", 0],
          autoplay: false,
        },
      },
      {
        //Accomplishments title slide in from top
        identifier: "#channel-card-2",
        midPointOffset: 0.5,
        animationParams: {
          ...animeJSBaseParams,
          targets: "#channel-card-2",
          translateX: ["-100vw", 0],
          autoplay: false,
        },
      },
      {
        //Accomplishments title slide in from top
        identifier: "#channel-card-3",
        midPointOffset: 0.5,
        animationParams: {
          ...animeJSBaseParams,
          targets: "#channel-card-3",
          translateX: ["100vw", 0],
          autoplay: false,
        },
      },
    ];
    init(targets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        index === 1 || index === 3
          ? "channel-card odd-card"
          : "channel-card even-card"
      }
      id={`channel-card-${index}`}
    >
      <div className="channel-card-section">
        <div className="channel-card-icon-container">
          <img
            src={cardInfo.channelIconUrl}
            alt="icon"
            className="channel-card-icon"
          />
        </div>
        <div
          className="channel-card-title"
          dangerouslySetInnerHTML={{
            __html: cardInfo.name[i18n.language as Language],
          }}
        ></div>
      </div>
      <div
        className="channel-card-text"
        dangerouslySetInnerHTML={{
          __html: cardInfo.description[i18n.language as Language] ?? "",
        }}
      ></div>
    </div>
  );
};

export default ChannelCard;
