import { useEffect } from 'react'
import { Language } from "../../interfaces/general";
import { LocalizedText } from '../../interfaces/general'
import { useTranslation } from "react-i18next";
import { animeJSBaseParams } from "../../config/animejs.config";
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations";

interface buttonData{
  buttonHelper:LocalizedText,
  buttonTitle:LocalizedText,
  iconUrl:string
}
const ContactForm:React.FC<{buttonData:buttonData}> = ({buttonData:buttonData}) => {
    //translation hook
  const { i18n } = useTranslation();
    //Animations hook
  const {init, execute } = useViewAnimations();

  useEffect(() => {
    const target: AnimationTarget[] = [
      {
    //about us bottom paragraphs slide in from bottom
    identifier: ".contact-form",
    viewOffset: [0, 100],
    animationParams: {
      ...animeJSBaseParams,
      targets: [".contact-form"],
      translateY: [100, 0],
      autoplay: false,
      duration: 800,
      },
    },
    ]
    init(target)
  }, [])
  if (!buttonData) {
    return <>Button</>
  }
    return (
        <form className='contact-form'>
            <section className='form-input-group'>
          <input type="text" placeholder='Name' className='form-input' />
          <input type="mail" placeholder='Mail' className='form-input' />
          <input type="phone" placeholder='Phone Number' className='form-input'/>
          <textarea cols={5} rows={5} placeholder='Message'  className='form-input form-textarea'/>
            </section>
            <button className='round-border' 
      onMouseEnter={() =>
        execute({
          ...animeJSBaseParams,
          targets: ['.round-border'],
          scale: ["100%", "110%"],
          rotate: ["0deg", "0.01deg"],
        })
      }
      onMouseLeave={() =>
        execute({
          ...animeJSBaseParams,
          targets:['.round-border'],
          scale: ["110%", "100%"],
          rotate: ["0.01deg", "0deg"],
        })
      }
      >
          <div className='form-button'>
            <img className='form-arrow' src={buttonData.iconUrl} alt='arrow'/>
          <div className='form-button-text' dangerouslySetInnerHTML={{__html:buttonData.buttonTitle[i18n.language as Language]  ?? "",}}> 
              </div>
          <div className='form-button-helper' dangerouslySetInnerHTML={{__html:buttonData.buttonHelper[i18n.language as Language]  ?? "",}}></div>
          </div>
            </button>
        </form>
    )
}

export default ContactForm
