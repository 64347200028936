//Local Imports
import { useState, useEffect, useContext } from "react"
import { Mosaic } from "../../components/mosaic/mosaic"
import Wave from "../../components/wave/wave"
import ContactCard from "../../components/contactCard/contactCard"
import Footer from "../../components/footer/footer"
import { Project } from "../../interfaces/projects"
import { WorkWithUsDoc } from "../../interfaces/work-with-us"
//context
import { AnalyticsContext } from "../../context/analytics.context"
import { PreloadContext } from "../../context/preload.context"
//hooks
import { useTranslation } from "react-i18next"
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations"
import { Language, LocalizedText } from "../../interfaces/general"
import { animeJSBaseParams } from "../../config/animejs.config"

const WorkView = () => {
  //Contexts
  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext)

  //Preload context
  const { getViewData, getProjectsData, isReadyToLoad } =
    useContext(PreloadContext)

  //State
  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false)

  //Content
  const [content, setContent] = useState<WorkWithUsDoc | undefined>(
    getViewData("Work") as WorkWithUsDoc | undefined
  )

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Update flag
      setPageViewSent(true)
    }
    // eslint-disable-next-line
  }, [content])

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setContent(getViewData("Work") as WorkWithUsDoc | undefined)
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Work")])
  //translation hook
  const { i18n } = useTranslation()

  //Animations hook
  const { init } = useViewAnimations()

  const [projects, setProjects] = useState<Project[]>([])

  const [filteredProjects, setFilteredProjects] = useState<Project[]>([])

  const [selectedCategory, setSelectedCategory] = useState<string>("")
  const [queryString, setQueryString] = useState<string>("")

  function getcategoryArray(){
    let categoryArray: LocalizedText[] = []
    projects.forEach((project) =>{
      let alreadyInArray = false;
      for (let i = 0; i < categoryArray.length; i++) {
        if (localizedTextsAreEqual(project.category,categoryArray[i])) {
          alreadyInArray = true
          break
        };
      }
      if (!alreadyInArray) {
        categoryArray.push(project.category)
      }
    })
    return categoryArray;
  }
  function localizedTextsAreEqual(textOne:LocalizedText,textTwo:LocalizedText){
    return textOne['en'] === textTwo['en'];
  }
  //On filter values change
  useEffect(() => {
    setFilteredProjects(
      projects.filter(
        ({ name, category }) =>
          (queryString
            ? name.toLocaleLowerCase().includes(queryString)
            : true) &&
          (selectedCategory
            ? (
                category[i18n.language as Language] ?? category.en
              ).toLocaleLowerCase() === selectedCategory
            : true)
      )
    )
  }, [queryString, selectedCategory])

  useEffect(() => {
    if (isReadyToLoad) {
      let allProjects = getProjectsData()
      let projectsArray: Project[] = []
      content?.banner.projectIds.forEach((projectID) => {
        let projectData = allProjects?.find((project) => {
          return project.id === projectID
        })
        projectsArray.push(projectData as Project)
      })
      setProjects(projectsArray)
      setFilteredProjects(projectsArray)
    }
  }, [content])

  useEffect(() => {
    //Scroll on view rendered
    if (window) window.scrollTo(0, 0)
    /**Animations targets */
    const targets: AnimationTarget[] = [
      //Work header description titleslide in from top
      {
        identifier: ".work-header-description-title",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-header-description-title",
          translateY: [-100, 0],
          autoplay: false,
        },
      },
      {
        //Work header star icon slide in from top
        identifier: ".work-header-description-star",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-header-description-star",
          translateY: [-100, 0],
          autoplay: false,
        },
      },
      {
        //Work header description slide in from top
        identifier: ".work-header-description-intro",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-header-description-intro",
          translateY: [-100, 0],
          autoplay: false,
        },
      },
      {
        identifier: ".work-header-description-paragraph",
        viewOffset: [0, 150],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-header-description-paragraph",
          translateY: [150, 0],
          autoplay: false,
        },
      },
      {
        identifier: ".work-header-navBar",
        viewOffset: [0, 150],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".work-header-navBar",
          translateY: [150, 0],
          autoplay: false,
        },
      },
    ]
    init(targets)
  }, [])
  return (
    <>
      <div className="background-color-cream-2">
        <section className="work-header">
          <div className="work-header-description">
            <img
              className="work-header-description-star"
              src={content?.header.iconUrl}
              alt="star-icon"
            />
            <div
              className="work-header-description-intro"
              dangerouslySetInnerHTML={{
                __html: content?.header.intro[i18n.language as Language] ?? "",
              }}
            ></div>
            <div
              className="work-header-description-title"
              dangerouslySetInnerHTML={{
                __html: content?.header.title[i18n.language as Language] ?? "",
              }}
            ></div>
            <div
              className="work-header-description-paragraph"
              dangerouslySetInnerHTML={{
                __html: content?.header.text[i18n.language as Language] ?? "",
              }}
            ></div>
          </div>
          <div className="work-header-navBar">
            <div className="navBar-text">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    content?.header.searchBar.helper[
                      i18n.language as Language
                    ] ?? "",
                }}
              ></div>
            </div>
            <input
              type="text"
              placeholder={
                i18n.language === "es" ? "Nombre del proyecto" : "Project name"
              }
              onChange={({ target: { value } }) => {
                setQueryString(value.toLocaleLowerCase())
              }}
              className="navBar-input"
            />
            <select
              className="filter"
              onChange={({ target: { value } }) => {
                setSelectedCategory(value.toLocaleLowerCase())
              }}
              name="project"
              id="project"
            >
              <option value="">
                {i18n.language === "es"
                  ? "-- Todos los proyectos --"
                  : "-- All projects --"}
              </option>
              {/* Iterating through all unique values */}
              {getcategoryArray().map(( category ) => 
                 { return (<option
                  value={category[i18n.language as Language] ?? category.en}
                >
                  {category[i18n.language as Language] ?? category.en}
                </option>)}
              )}
            </select>
          </div>
        </section>
        <div className="background-gradient-black">
          <Wave color="#fffaf3" position="TOP"></Wave>
          <div className="grid doubleCol relative-sm-up">
            {filteredProjects.map((value: any) => {
              return <Mosaic project={value} icons={content?.icons} />
            })}
          </div>
          <Wave color="#fffaf3" position="BOTTOM"></Wave>
        </div>
        <section className="main-container">
          <div className=" contact-card-container">
            <ContactCard cardInfo={content?.contactCard} />
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default WorkView
