//react imports
import { useContext, useEffect, useState } from "react"
//third parties imports
import { NavLink, useLocation } from "react-router-dom"
//context
import { PreloadContext } from "../../context/preload.context"
//interface
import { FooterDoc } from "../../interfaces/footer"
import { NavigationDoc, NavigationItem } from "../../interfaces/navigation"
import { Language } from "../../interfaces/general"
//hooks
import { useTranslation } from "react-i18next"
//css
import "./menu.scss"
//assets
import Star from "../../assets/elements/star.svg"

const Menu = ({
  activeMenu,
  sizeStatus,
  showMenu,
  data,
}: {
  activeMenu: boolean
  sizeStatus: number
  showMenu: () => void
  data: NavigationDoc
}) => {
  const location = useLocation()

  const { getViewData, isReadyToLoad } = useContext(PreloadContext)

  //translation hook
  const { i18n } = useTranslation()

  //Data
  //Content
  const [content, setContent] = useState<FooterDoc | undefined>(
    getViewData("Footer") as FooterDoc | undefined
  )

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setContent(getViewData("Footer") as FooterDoc | undefined)
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Footer")])

  return (
    <>
      <div className={activeMenu ? "menu active " : "menu "}>
        <div className="menu-container main-container main-container-top">
          <ul className="menu-links">
            {data.items.map((item: NavigationItem, index: number) => (
              <li
                className={
                  location.pathname === item.route
                    ? "menu-link menu-link-selected"
                    : "menu-link"
                }
                key={index}
              >
                <NavLink
                  to={item.route}
                  activeStyle={{
                    color: "#FFFAF3",
                    opacity: "100%",
                  }}
                  exact={true}
                  className="menu-link-text"
                  onClick={showMenu}
                >
                  {item.name[i18n.language as Language]}
                  {location.pathname === item.route && (
                    <img
                      src={Star}
                      alt="star"
                      className="menu-link-selected-icon"
                    />
                  )}
                  <div
                    className={
                      location.pathname === item.route
                        ? "menu-link-number menu-link-number-selected"
                        : "menu-link-number"
                    }
                  >
                    {" "}
                    0{index + 1}
                  </div>
                </NavLink>
              </li>
            ))}
          </ul>
          {/* No creo que sea la mejor forma porque se repite el codigo pero fue la que pude lograr para que fuese igual al diseño */}
          {sizeStatus > 768 ? (
            <>
              <div className="menu-social-media-icons">
                {!!data.socialMediaIcons[0].route && (
                  <div className="menu-icon-container">
                    <a
                      href={data.socialMediaIcons[0].route}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={data.socialMediaIcons[0].iconUrl}
                        alt="icon"
                        className="menu-social-media-icon"
                      />
                    </a>
                  </div>
                )}
                {!!data.socialMediaIcons[1].route && (
                  <div className="menu-icon-container">
                    <a
                      href={data.socialMediaIcons[1].route}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={data.socialMediaIcons[1].iconUrl}
                        alt="icon"
                        className="menu-social-media-icon"
                      />
                    </a>
                  </div>
                )}
                {!!data.socialMediaIcons[2].route && (
                  <div className="menu-icon-container">
                    <a
                      href={data.socialMediaIcons[2].route}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={data.socialMediaIcons[2].iconUrl}
                        alt="icon"
                        className="menu-social-media-icon-be "
                      />
                    </a>
                  </div>
                )}
              </div>
              <div className="menu-details">
                <div className="menu-details-ofi">
                  <div
                    className="menu-details-title"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.menuInformation.addressTitle[
                          i18n.language as Language
                        ],
                    }}
                  ></div>
                  <div
                    className="menu-details-text"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.menuInformation.country[i18n.language as Language],
                    }}
                  ></div>
                  <div
                    className="menu-details-text"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.menuInformation.address[i18n.language as Language],
                    }}
                  ></div>
                  <div
                    className="menu-details-text"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.menuInformation.postalCode[
                          i18n.language as Language
                        ],
                    }}
                  ></div>
                </div>
                <div className="menu-details-info">
                  <div
                    className="menu-details-title"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.menuInformation.informationTitle[
                          i18n.language as Language
                        ],
                    }}
                  ></div>
                  <div
                    className="menu-details-text"
                    dangerouslySetInnerHTML={{
                      __html: data.menuInformation.email,
                    }}
                  ></div>
                  <div
                    className="menu-details-text"
                    dangerouslySetInnerHTML={{
                      __html: data.menuInformation.phoneNumber,
                    }}
                  ></div>
                </div>
              </div>
            </>
          ) : (
            <div className="menu-responsive-container">
              <div className="menu-social-media-icons">
                <div className="menu-icon-container">
                  <a
                    href={data.socialMediaIcons[0].route}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={data.socialMediaIcons[0].iconUrl}
                      alt="icon"
                      className="menu-social-media-icon"
                    />
                  </a>
                </div>
                <div className="menu-icon-container">
                  <a
                    href={data.socialMediaIcons[1].route}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={data.socialMediaIcons[1].iconUrl}
                      alt="icon"
                      className="menu-social-media-icon"
                    />
                  </a>
                </div>
                <div className="menu-icon-container">
                  <a
                    href={data.socialMediaIcons[2].route}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={data.socialMediaIcons[2].iconUrl}
                      alt="icon"
                      className="menu-social-media-icon-be "
                    />
                  </a>
                </div>
              </div>
              <div className="menu-details">
                <div className="menu-details-ofi">
                  <div
                    className="menu-details-title"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.menuInformation.addressTitle[
                          i18n.language as Language
                        ],
                    }}
                  ></div>
                  <div
                    className="menu-details-text"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.menuInformation.country[i18n.language as Language],
                    }}
                  ></div>
                  <div
                    className="menu-details-text"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.menuInformation.address[i18n.language as Language],
                    }}
                  ></div>
                  <div
                    className="menu-details-text"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.menuInformation.postalCode[
                          i18n.language as Language
                        ],
                    }}
                  ></div>
                </div>
                <div className="menu-details-info">
                  <div
                    className="menu-details-title"
                    dangerouslySetInnerHTML={{
                      __html:
                        data.menuInformation.informationTitle[
                          i18n.language as Language
                        ],
                    }}
                  ></div>
                  <div
                    className="menu-details-text"
                    dangerouslySetInnerHTML={{
                      __html: data.menuInformation.email,
                    }}
                  ></div>
                  <div
                    className="menu-details-text"
                    dangerouslySetInnerHTML={{
                      __html: data.menuInformation.phoneNumber,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>
        <hr />
        <footer className="footer">
          <div
            className="privacy-text"
            dangerouslySetInnerHTML={{
              __html: content!.footerText[i18n.language as Language],
            }}
          ></div>
        </footer>
      </div>
    </>
  )
}

export default Menu
