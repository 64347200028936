import { useFirestore, useFirestoreDocDataOnce } from "reactfire";

//Sustituido por UseContent
//Por favor eliminarlo cuando quites los lugares donde lo utilizas,

const useContentData = (id: string) => {
  const firestore = useFirestore();

  return useFirestoreDocDataOnce(
    firestore
      .collection("StudioBrass")
      .doc("development")
      .collection("Content")
      .doc(id)
  ) as any;
};

export default useContentData;
