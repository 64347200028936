//react imports
import { useContext, useEffect, useState } from "react"
//third parties imports
import { NavLink, useLocation } from "react-router-dom"
//hooks
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations"
import { animeJSBaseParams } from "../../config/animejs.config"
//interface
import { NavigationDoc, NavigationItem } from "../../interfaces/navigation"
//context
import { PreloadContext } from "../../context/preload.context"
//css
import "./navbar.scss"
import { useTranslation } from "react-i18next"
import { Language } from "../../interfaces/general"
import LanguageIcon from "../../assets/elements/language.svg"
//navbar component
const Navbar = ({
  showMenu,
  activeMenu,
}: {
  showMenu: () => void
  activeMenu: boolean
}) => {
  //Preload context
  const { getViewData, isReadyToLoad } = useContext(PreloadContext)

  //translation hook
  const { i18n } = useTranslation()

  //Content
  const [data, setData] = useState<NavigationDoc | undefined>(
    getViewData("Navigation") as NavigationDoc | undefined
  )

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setData(getViewData("Navigation") as NavigationDoc | undefined)
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Navigation")])

  let location = useLocation()

  const locationsWithWhiteLogo = ["/contact-us"]

  const [whiteLogoSite, setWhiteLogoSite] = useState(false)

  useEffect(() => {
    if (locationsWithWhiteLogo.includes(location.pathname)) {
      setWhiteLogoSite(true)
      return
    }
    setWhiteLogoSite(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function changeLanguage() {
    if (i18n.language === "en") {
      i18n.changeLanguage("es")
    } else {
      i18n.changeLanguage("en")
    }
  }

  //Animations hook
  const { init } = useViewAnimations()

  useEffect(() => {
    /**Animation targets */
    const targets: AnimationTarget[] = [
      {
        //Logo slide in
        identifier: ".nav-logo-img",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".nav-logo-img",
          translateX: ["-100vw", 0],
          delay: (_el, i, _l) => 300 * i,
        },
      },
      {
        //Items slide in from top
        identifier: "menu-link",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".menu-link",
          done: true,
          translateY: ["-100vh", 0],
          delay: (_el, i, _l) => 100 * i,
        },
      },
      {
        //menu icon slide in from right
        identifier: ".nav-hamburger-icon",
        animationParams: {
          ...animeJSBaseParams,
          targets: ".nav-hamburger-icon",
          translateX: ["100vw", 0],
          delay: (_el, i, _l) => 300 * i,
        },
      },
    ]

    init(targets)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!data) return <div></div>
  return (
    <nav className={activeMenu ? "nav nav-fixed" : "nav"}>
      <div className="nav-logo">
        <NavLink exact to="/">
          <img
            src={data.logoUrl}
            alt="logo"
            className={
              activeMenu || whiteLogoSite
                ? "nav-logo-img nav-logo-img-white"
                : "nav-logo-img"
            }
          />
        </NavLink>
      </div>
      {!activeMenu && (
        <ul className="nav-menu">
          {data.items.map((item: NavigationItem, index: number) => (
            <li className="menu-link" key={index}>
              <NavLink
                to={item.route}
                activeStyle={{
                  color: "#ff4501",
                }}
                exact={true}
                className="nav-link"
              >
                {item.name[i18n.language as Language]}
              </NavLink>
            </li>
          ))}
        </ul>
      )}

      <div className="nav-hamburger">
        <div
          className="nav-lng"
          onClick={() => {
            changeLanguage()
          }}
        >
          <img src={LanguageIcon} alt="menu" className="nav-language-icon" />
        </div>
        <div
          className="nav-hamburger-cont"
          onClick={() => {
            showMenu()
          }}
        >
          <img
            src={data.menuIconUrl}
            alt="menu"
            className={
              !activeMenu
                ? "nav-hamburger-icon"
                : "nav-hamburger-icon nav-hamburger-icon-white"
            }
          />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
