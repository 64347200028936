//Local Imports
import { useContext, useEffect, useState } from "react"
import ContactForm from "../../components/contactForm/contactForm"
import { animeJSBaseParams } from "../../config/animejs.config"
import { Language } from "../../interfaces/general"
// Context
import { AnalyticsContext } from "../../context/analytics.context"
import { PreloadContext } from "../../context/preload.context"
//hooks
import { useTranslation } from "react-i18next"
import useViewAnimations, {
  AnimationTarget,
} from "../../hooks/useViewAnimations"
import { ContactUsDoc } from "../../interfaces/contact-us"
import { FooterDoc } from "../../interfaces/footer"
const Contact = () => {
  //translation hook
  const { i18n } = useTranslation()

  //Animations hook
  const { init } = useViewAnimations()
  //Contexts
  //Analytics context
  const { emitPageView } = useContext(AnalyticsContext)

  //Preload context
  const { getViewData, isReadyToLoad } = useContext(PreloadContext)

  //Content
  const [content, setContent] = useState<ContactUsDoc | undefined>(
    getViewData("Contact") as ContactUsDoc | undefined
  )
  const [footerContent,setFooterContent] = useState<FooterDoc | undefined>(getViewData('Footer') as FooterDoc | undefined)
  //State
  //Page view sent flag
  const [pageViewSent, setPageViewSent] = useState(false)

  //Emit page view event
  useEffect(() => {
    if (content && !pageViewSent) {
      //Home content fetched and page view not sent yet

      //Update flag
      setPageViewSent(true)
    }
    // eslint-disable-next-line
  }, [content])

  //On is ready to load
  useEffect(() => {
    if (isReadyToLoad) {
      setContent(getViewData("Contact") as ContactUsDoc | undefined)
    }
    // eslint-disable-next-line
  }, [isReadyToLoad, getViewData("Contact")])

  useEffect(() => {
    //Scroll on view rendered
    if (window) window.scrollTo(0, 0)
    /**Animations targets */
    const targets: AnimationTarget[] = [
      {
        identifier: ".contact-side-details",
        viewOffset: [0, -50],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".contact-side-details",
          translateX: ["-50vw", 0],
          duration: 1000,
          autoplay: false,
        },
      },
      {
        //about us bottom paragraphs slide in from bottom
        identifier: ".about-us-details",
        viewOffset: [0, 500],
        animationParams: {
          ...animeJSBaseParams,
          targets: [".about-us-details-info"],
          translateY: [500, 0],
          autoplay: false,
          duration: 800,
        },
      },
      {
        //channels title slide in from top
        identifier: ".contact-main-presentation-intro",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".contact-main-presentation-intro",
          translateY: [-100, 0],
          autoplay: false,
        },
      },
      {
        //channels title slide in from top
        identifier: ".contact-main-presentation-title",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".contact-main-presentation-title",
          translateY: [-100, 0],
          autoplay: false,
        },
      },
      {
        //channels title slide in from top
        identifier: ".contact-main-presentation-text",
        viewOffset: [0, -100],
        animationParams: {
          ...animeJSBaseParams,
          targets: ".contact-main-presentation-text",
          translateY: [-100, 0],
          autoplay: false,
        },
      },
    ]
    init(targets)
  }, [])
  if (!content) {
    return <></>
  }
  return (
    <section className="contact-container">
      <section className="contact-side">
        <div className="contact-side-container">
          <div className="contact-side-details">
            <div
              className="contact-side-details-title"
              dangerouslySetInnerHTML={{
                __html:
                  content?.location.title[i18n.language as Language] ??
                  content?.location.title.en,
              }}
            ></div>
            <div className="contact-side-details-text">
              <div>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      content.location.country[i18n.language as Language] ??
                      content.location.country.en,
                  }}
                ></span>{" "}
                <span> -</span>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    content.location.street[i18n.language as Language] ??
                    content.location.street.en,
                }}
              ></div>
              <div>
                {" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      content.location.city[i18n.language as Language] ??
                      content.location.city.en,
                  }}
                ></span>{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      content.location.postalCode[i18n.language as Language] ??
                      content.location.postalCode.en,
                  }}
                ></span>
              </div>
            </div>

            <div className="contact-side-details-info">
              <div
                className="contact-side-details-title"
                dangerouslySetInnerHTML={{
                  __html:
                    content.contactInfo.title[i18n.language as Language] ??
                    content.contactInfo.title.en,
                }}
              ></div>
              <div
                className="contact-side-details-text"
                dangerouslySetInnerHTML={{
                  __html: content.contactInfo.email,
                }}
              ></div>
              <div
                className="contact-side-details-text"
                dangerouslySetInnerHTML={{
                  __html: content.contactInfo.phone,
                }}
              ></div>
            </div>
          </div>
        </div>
        <footer className="contact-footer" dangerouslySetInnerHTML={{__html: footerContent?.footerText[i18n.language as Language] ?? ''}}>

        </footer>
      </section>
      <section className="contact-main">
        <div className="contact-main-presentation">
          <div
            className="contact-main-presentation-intro"
            dangerouslySetInnerHTML={{
              __html:
                content.mainSection.intro[i18n.language as Language] ?? "",
            }}
          ></div>
          <div
            className="contact-main-presentation-title"
            dangerouslySetInnerHTML={{
              __html:
                content.mainSection.title[i18n.language as Language] ?? "",
            }}
          ></div>
          <div
            className="contact-main-presentation-text"
            dangerouslySetInnerHTML={{
              __html: content.mainSection.text[i18n.language as Language] ?? "",
            }}
          ></div>
        </div>
        <hr className="hr-contact" />
        <ContactForm buttonData={content.button} />
      </section>
    </section>
  )
}
export default Contact
