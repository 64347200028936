import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Author } from "../interfaces/author";
import { AnyDoc } from "../interfaces/general";
import { Project } from "../interfaces/projects";
import { getEnvironment } from "../utils/enviroment.utils";

const useContent = (): {
  getAll: () => AnyDoc[];
  getProjects: () => Project[];
  getAuthors: () => Author[];
} => {
  /**Firestore reference */
  const firestore = useFirestore();

  const contentCollection = firestore
    .collection("StudioBrass")
    .doc(getEnvironment())
    .collection("Content");

  const projectCollection = firestore
  .collection('StudioBrass')
  .doc(getEnvironment())
  .collection('Content')
  .doc('Projects')
  .collection('Projects')

  const authorCollection = firestore
  .collection('StudioBrass')
  .doc(getEnvironment())
  .collection('Content')
  .doc('Projects')
  .collection('Authors')
  const { data: collectionData } = useFirestoreCollectionData<AnyDoc>(
    contentCollection,
    {
      initialData: null,
      idField: "contentName",
    }
  );

  const { data: collectionProjects } =useFirestoreCollectionData<Project>(
    projectCollection
  )

  const { data: collectionAuthors } =useFirestoreCollectionData<Author>(
    authorCollection
  )
  const getAll = (): AnyDoc[] => collectionData;
  const getProjects = (): Project[] => collectionProjects;
  const getAuthors = (): Author[] => collectionAuthors;
  return {
    getAll,
    getProjects,
    getAuthors
  };
};

export default useContent;
