//Third parties
import { Helmet } from "react-helmet";
//Local imports
import { SEODataView } from "../../interfaces/seo";

/**SEO head component */
const SEOHead = ({
  description,
  keywords,
  og,
  title,
  twitter,
}: SEODataView) => {
  //Data
  /**Website url */
  const url = window
    ? `${window.location.protocol}//${window.location.host}`
    : "https://studio-brass.com";

  /**Og fallback image */
  const ogFallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/studio-brass.appspot.com/o/Images%2FStudio%2Fhero%20(1).jpg?alt=media&token=e6b163dc-b332-4adc-a767-9d3d7659bf49";

  /**Twitter fallback image */
  const twitterFallbackImage =
    "https://firebasestorage.googleapis.com/v0/b/studio-brass.appspot.com/o/Images%2FStudio%2Fhero%20(1).jpg?alt=media&token=e6b163dc-b332-4adc-a767-9d3d7659bf49";

  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: "en" }}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: "og:title",
          content: og.title || title,
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: url },
        {
          property: "og:description",
          content: og.description || description,
        },
        {
          property: "og:image",
          content: og.image ? og.image : ogFallbackImage,
        },
        {
          property: "twitter:title",
          content: twitter.title || title,
        },
        { property: "twitter:type", content: "website" },
        { property: "twitter:url", content: url },
        {
          property: "twitter:description",
          content: twitter.description || description,
        },
        {
          property: "twitter:image",
          content: twitter.image ? twitter.image : twitterFallbackImage,
        },
      ]}
    />
  );
};

export default SEOHead;
