import "./spinner.scss";

//spinner component
const Spinner = () => {
  return (
    <div className="spinner-layout">
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
